// Generated by Framer (f030ee3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, useComponentViewport, useLocaleInfo, useVariantState, withCodeBoundaryForOverrides, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { themeSwicther } from "../codeFile/Dark_Light_Mode.tsx";
const MotionDivThemeSwictherojqlf = withCodeBoundaryForOverrides(motion.div, {nodeId: "Vh1smGe6S", override: themeSwicther, scopeId: "xYb8ZaGjl"});

const serializationHash = "framer-74tli"

const variantClassNames = {WI8Acghjg: "framer-v-k8fjho"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, link, width, ...props}) => { return {...props, uJkd7rQi4: link ?? props.uJkd7rQi4} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, uJkd7rQi4, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "WI8Acghjg", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={uJkd7rQi4} motionChild nodeId={"WI8Acghjg"} scopeId={"xYb8ZaGjl"}><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-k8fjho", className, classNames)} framer-10052gh`} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"WI8Acghjg"} ref={refBinding} style={{...style}}><MotionDivThemeSwictherojqlf className={"framer-ojqlf"} layoutDependency={layoutDependency} layoutId={"Vh1smGe6S"} style={{backgroundColor: "var(--token-a31d5043-947c-4b72-8ac5-3a2cd17fc6f0, rgb(38, 38, 38))", borderBottomLeftRadius: 99, borderBottomRightRadius: 99, borderTopLeftRadius: 99, borderTopRightRadius: 99}}/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-74tli.framer-10052gh, .framer-74tli .framer-10052gh { display: block; }", ".framer-74tli.framer-k8fjho { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 2px; height: 20px; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: 20px; }", ".framer-74tli .framer-ojqlf { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 12px); overflow: hidden; position: relative; width: 12px; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-74tli.framer-k8fjho { gap: 0px; } .framer-74tli.framer-k8fjho > * { margin: 0px; margin-left: calc(2px / 2); margin-right: calc(2px / 2); } .framer-74tli.framer-k8fjho > :first-child { margin-left: 0px; } .framer-74tli.framer-k8fjho > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"uJkd7rQi4":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerxYb8ZaGjl: React.ComponentType<Props> = withCSS(Component, css, "framer-74tli") as typeof Component;
export default FramerxYb8ZaGjl;

FramerxYb8ZaGjl.displayName = "Navigation / Theme Switch";

FramerxYb8ZaGjl.defaultProps = {height: 20, width: 20};

addPropertyControls(FramerxYb8ZaGjl, {uJkd7rQi4: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerxYb8ZaGjl, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})